<template>
  <div id="mobile">
    <div class="top">
      <div class="hbox">
        <div class="box">
          <h1>
            <img src="@/assets/images/logo.png" alt="" />
            <span class="title">{{ siteName }}</span>
          </h1>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="c_center">
        <template v-if="connect !== undefined && connect.length > 0">
          <div class="wz">
            <font>【重要通知】：</font
            >为了防止域名遭劫持，造成部分地区无法正常访问，请您收藏至少三条网址进入本公司并牢记！<br />
            <font
              >温馨提示：中国部分地区网址劫持太猖狂，望广大新老客户认准{{
                siteName
              }}官网！以免受骗</font
            ><br />
            <p>
              <font
                >联系客服：<span>{{ connect[0].title }}</span>
                <a :href="connect[0].address">{{ connect[0].name }}</a
                ><br /><span>{{ connect[1].title }}</span>
                <a :href="connect[1].address">{{ connect[1].name }}</a></font
              >
            </p>
            <p>
              <font
                ><span>{{ connect[2].title }}</span>
                <a :href="connect[2].address">{{ connect[2].name }}</a
                ><br /><span>{{ connect[3].title }}</span>
                <a :href="connect[3].address">{{ connect[3].name }}</a></font
              >
            </p>
          </div>
        </template>
        <template v-if="links !== undefined && links.length > 0">
          <div class="ym" v-for="(item, index) in links" :key="index">
            <div class="title">{{ item.title }}</div>
            <template
              v-if="item.sitelist !== undefined && item.sitelist.length > 0"
            >
              <div class="xl">
                <p v-for="(siteItem, siteIdx) in item.sitelist" :key="siteIdx">
                  <a target="_blank" :href="siteItem.link">{{
                    siteItem.name
                  }}</a>
                </p>
              </div>
              <div class="xl">
                <span
                  class="site-name"
                  v-for="(siteItem, siteIdx) in item.sitelist"
                  :key="siteIdx"
                  >{{ siteItem.site }}</span
                >
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileHome",
  data() {
    return {
      siteName: "",
      links: [],
      connect: [],
    };
  },
  mounted() {
    this.getSiteData();
  },
  methods: {
    getSiteData() {
      setTimeout(() => {
        if (window.links !== undefined && window.connect !== undefined) {
          this.siteName = window.siteName;
          this.links = window.links;
          this.connect = window.connect;
        } else {
          this.getSiteData();
        }
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mobile.scss";
</style>