<template>
  <div id="app">
    <template v-if="currentPc === true">
      <pchome />
    </template>
    <template v-else-if="currentMobile === true">
      <mobile-home />
    </template>
  </div>
</template>

<script>
import pchome from "@/components/pc";
import mobileHome from "@/components/mobile";

export default {
  name: "App",
  components: {
    pchome,
    mobileHome,
  },
  data() {
    return {
      currentPc: false,
      currentMobile: false,
      moblieDeviceType: "",
      type: window.GLOBAL.browser.versions,
    };
  },
  beforeCreate() {
    window.GLOBAL = {
      browser: {
        versions: (function () {
          var e = navigator.userAgent;
          return {
            qq: "qq" === e.match(/\sQQ/i),
            uc: -1 < e.indexOf("UCBrowser"),
            ios: !!e.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
            iPad: -1 < e.indexOf("iPad"),
            gecko: -1 < e.indexOf("Gecko") && e.indexOf("KHTML") < 0,
            presto: -1 < e.indexOf("Presto"),
            webkit: -1 < e.indexOf("AppleWebKit"),
            mobile: !!e.match(/AppleWebKit.*Mobile.*/),
            iPhone: -1 < e.indexOf("iPhone"),
            webApp: e.indexOf("Safari") < 0,
            safari:
              !!e.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) &&
              -1 < e.indexOf("Safari"),
            weChat: -1 < e.indexOf("MicroMessenger"),
            aliPay: !!e.match(/Alipay/i),
            trident: -1 < e.indexOf("Trident"),
            android: -1 < e.indexOf("Android") || -1 < e.indexOf("Linux"),
            qqBrowser: -1 < e.indexOf("MQQBrowser"),
          };
        })(),
        language: (
          navigator.browserLanguage || navigator.language
        ).toLowerCase(),
      },
    };
  },
  mounted() {
    var BrowserVersion = window.GLOBAL.browser.versions;
    var nua = navigator.userAgent;
    var is_android =
      nua.indexOf("Mozilla/5.0") > -1 &&
      nua.indexOf("Android ") > -1 &&
      nua.indexOf("AppleWebKit") > -1 &&
      !(nua.indexOf("Chrome") > -1);

    if (BrowserVersion.android == true || is_android == true) {
      this.currentMobile = true;
      if (BrowserVersion.weChat == true) {
        this.mobileDeviceType = "wx";
      } else if (
        /MQQBrowser/i.test(navigator.userAgent) &&
        /\sQQ/i.test(navigator.userAgent.split("MQQBrowser"))
      ) {
        this.mobileDeviceType = "qq";
      } else {
        this.mobileDeviceType = "android";
      }
    } else if (
      BrowserVersion.ios == true ||
      BrowserVersion.iPhone == true ||
      BrowserVersion.iPad == true
    ) {
      this.currentMobile = true;
      if (BrowserVersion.weChat == true) {
        this.mobileDeviceType = "wx";
      } else if (/\sQQ/i.test(navigator.userAgent)) {
        this.mobileDeviceType = "qq";
      } else {
        this.mobileDeviceType = "ios";
      }
    } else if (BrowserVersion.mobile == true) {
      this.currentMobile = true;
      this.mobileDeviceType = "mobile";
    } else {
      this.currentPc = true;
    }
    this.loadLinkJson();
  },
  methods: {
    loadLinkJson() {
      var xobj = new XMLHttpRequest();
      xobj.overrideMimeType("application/json");
      xobj.open("GET", "./link.json", true);
      xobj.onreadystatechange = function () {
        if (xobj.readyState == 4 && xobj.status == "200") {
          var json = JSON.parse(xobj.responseText);
          // console.log("json", json);
          window.siteName = json.siteName;
          window.connect = json.connect;
          window.links = json.links;
        }
      };
      xobj.send(null);
    },
  },
};
</script>

<style>
@import "@/assets/styles/common.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0 auto;
}
</style>
